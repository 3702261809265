import * as Sentry from "@sentry/node";
import getConfig from "next/config";

import Redis from "ioredis";

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
export const apiHost = serverRuntimeConfig.apiHost
  ? serverRuntimeConfig.apiHost
  : publicRuntimeConfig.apiHost;
const redisHost = serverRuntimeConfig.redisHost;
const MAX_AGE = 60 * 60; // 1 hour
let redis;

export async function apiPost(path, params) {
  /*

    Can be called from client or server.

    */
  const url = apiHost + path;
  const args = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  };
  const result = await fetch(url, args);
  return await result.json();
}

export async function apiGet(path, params = {}) {
  /*

    Can be called from the client or server.

    */
  const url = new URL(apiHost + path);
  Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
  const result = await fetch(url.toString());
  if (result.status !== 200) {
    const error = new Error(`API returned ${result.status} for ${path}`);
    error.statusCode = 404;
    Sentry.captureException(error);
    throw error;
  }
  return await result.json();
}

export async function maybeCached({ key, path, params, cached }) {
  if (!redis && redisHost) {
    redis = new Redis(redisHost);
  }
  if (redis) {
    let blob = await redis.get(key);
    if (!cached || !blob) {
      const data = await apiGet(path, params);
      blob = JSON.stringify(data);
      await redis.set(key, blob, "ex", MAX_AGE);
    }
    return JSON.parse(blob);
  } else {
    return await apiGet(path, params);
  }
}

export async function getQuizzesForCategory({ slug, cached }) {
  const path = `/quizzes/category/${slug}/`;
  const key = `quizzes:category:${slug}`;
  return await maybeCached({ key, path, cached });
}

export async function getTrendingQuizzes({ cached }) {
  const path = `/quizzes/trending/quizzes/`;
  const key = `quizzes:trending-quizzes`;
  return await maybeCached({ key, path, cached });
}

export async function getPosts({ cached }) {
  const path = `/quizzes/posts/`;
  const key = `quizzes:posts`;
  return await maybeCached({ key, path, cached });
}

export async function getTrendingQuestions({ cached }) {
  const path = `/quizzes/trending/questions/`;
  const key = `quizzes:trending-questions`;
  return await maybeCached({ key, path, cached });
}

export async function getCategories({ cached }) {
  const path = `/quizzes/categories/`;
  const key = `quizzes:categories`;
  return await maybeCached({ key, path, cached });
}

export async function getQuiz({ slug, type, cached }) {
  const path = `/quizzes/quiz/${type}/${slug}/`;
  const key = `quiz:${type}:${slug}`;
  return await maybeCached({ key, path, cached });
}

export async function getListicle({ slug, cached }) {
  const path = `/quizzes/listicle/${slug}/`;
  const key = `listicle:${slug}`;
  return await maybeCached({ key, path, cached });
}

export async function getArticle({ slug, cached }) {
  const path = `/quizzes/article/${slug}/`;
  const key = `article:${slug}`;
  return await maybeCached({ key, path, cached });
}
