import theme from "theme";

function Logo({ darkBackground, size = 4 }) {
  return (
    <div className="logo">
      <style jsx>{`
        .logo img {
          width: ${size * 12}px;
          height: ${size * 12}px;
        }
        .logo div {
          font-size: ${size * 8}px;
          line-height: ${size * 8}px;
          color: ${darkBackground ? "#fff" : theme.hsl(theme.logoText)};
        }
        .logo .second {
          font-weight: bold;
        }
        .logo {
          display: flex;
          align-items: center;
        }
      `}</style>

      <a className="logo" href="/">
        <img src="/icons/001-superhero.svg" />
        <div>
          <span className="first">award</span>
          <span className="second">joy</span>
        </div>
      </a>
    </div>
  );
}

export default Logo;
