import css from "styled-jsx/css";

/* CSS HEX */
const palette = {
  gray: [240, 1, 30],
  blue: [198, 80, 49],
  red: [358, 100, 67],
  yellow: [44, 100, 61],
  // green: [83, 68, 47],
  green: [141, 80, 20],
};

function hsl(color) {
  return `hsla(${color[0]}, ${color[1]}%, ${color[2]}%, 1)`;
}

function lighten(color, amount = 1) {
  return [color[0], color[1], Math.min(100, color[2] + 5 * amount)];
}

function darken(color, amount = 1) {
  return [color[0], color[1], Math.max(0, color[2] - 5 * amount)];
}

function dark(color) {
  return [color[0], color[1], 20];
}

function light(color) {
  return [color[0], color[1], 90];
}

function hueArray(count) {
  return Array.from(Array(10).keys()).map((index) => (360 / count) * index);
}

function brightColors(count) {
  return hueArray(count).map((hue) => [hue, 100, 70]);
}

const paddingMin = 6;
const paddingMax = 12;
const leftColumnMin = 300;
const leftColumnMax = 300;
const rightColumnMin = 300;
const rightColumnMax = 300;
const mainColumnMin = 728;
const mainColumnMax = 728;
const containerMin = mainColumnMin + paddingMin + rightColumnMin;
// const containerMin = leftColumnMin + padding + mainColumnMin + rightColumnMin;
const containerMax = leftColumnMax + paddingMax + mainColumnMax + paddingMax + rightColumnMax;

const theme = {
  ...palette,
  brightColors,
  // ctaColor: [356, 75, 48], // cxl.com [214, 31, 44],
  ctaColor: [353, 98, 41], // cxl.com [208, 2, 27]
  ctaDefault: "#d41a30",
  ctaHover: "#d0021b",
  ctaDefault: "#48e391",
  ctaHover: "#52ffa3",
  ctaColor: "#000",

  ctaBackground: "#0bfd8e",
  ctaBackgroundHover: "#0bfd8e",
  ctaColor: "#292929",
  ctaColorHover: "#090909",
  // hw gb(72, 227, 176);
  primaryButtonBackground: lighten(palette.blue),
  primaryButtonBackgroundHover: lighten(palette.blue, 3),
  lightBorder: "#dfdfdf",
  secondaryButtonBackground: palette.blue,
  secondaryButtonBackgroundHover: lighten(palette.blue, 3),

  logoText: [198, 80, 49],

  darkBackground: palette.gray,
  darkBackground: [198, 25, 25], // "#08384d",
  lightBackground: lighten(palette.blue, 3),
  whiteBackground: [198, 100, 98],
  links: palette.blue,
  answerWrong: lighten(palette.red),
  answerCorrect: lighten(palette.green, 6),
  headerBackground: palette.yellow,
  // logoText: `hsla(198, 100%, 49%, 1)`, // light blue
  paddingMin: `${paddingMin}px`,
  paddingMax: `${paddingMax}px`,
  containerMin: `${containerMin}px`,
  containerMax: `${containerMax}px`,
  mainColumnMin: `${mainColumnMin}px`,
  mainColumnMax: `${mainColumnMax}px`,
  leftColumnMin: `${leftColumnMin}px`,
  leftColumnMax: `${leftColumnMax}px`,
  rightColumnMin: `${rightColumnMin}px`,
  rightColumnMax: `${rightColumnMax}px`,
  breakpoint1: "550px", // almost ALL mobile phones are under this, go for more detail
  breakpoint2: "830px", // almost ALL tablets in portrait are under this, go for white space
  hsl,
  light,
  dark,
  lighten,
  darken,
};
export default theme;

export const quizHeading = css`
  h1 {
    padding: 24px 12px;
    line-height: 150%;
    text-align: center;
    background: ${theme.hsl(theme.darkBackground)};
    color: #fff;
    font-size: 30px;
    font-weight: normal;
  }
`;

export const quizQuestion = css`
  h1 {
    padding: 24px 12px;
    line-height: 150%;
    text-align: center;
    background: ${theme.hsl(theme.darkBackground)};
    color: #fff;
    font-size: 30px;
    font-weight: normal;
  }
`;
