import { HamburgerIcon, XIcon } from "components/Icons";
import Logo from "components/Logo";
import { cloudflareImage } from "lib/cloudflare";
import * as gtag from "lib/gtag";
import { getCategoryUrl } from "lib/urls";
import { useState } from "react";
import theme from "theme";
import AdUnit from "./AdUnit";

function Header({ categories, displayAd }) {
  const [showMenu, setShowMenu] = useState();
  function handleClickMenu() {
    setShowMenu(true);
    gtag.event({ category: "Navigation", action: "Menu", label: "Open Menu" });
  }
  return (
    <header>
      <style jsx>{`
        .navbar-container {
          background: #fff;
        }
        .navbar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          max-width: ${theme.containerMax};
          margin: 0 auto;
        }

        .navbar :global(.icon) {
          cursor: pointer;
          width: 28px;
          height: 28px;
          margin: 8px;
          fill: ${theme.hsl(theme.logoText)};
        }

        .menu-background {
          z-index: 100;
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: ${theme.hsl(theme.lightBackground)};
        }
        .menu {
          margin: 0 auto;
          position: relative;
          height: 100%;
          min-height: ${(categories.length + 1) * 72}px;
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: ${theme.containerMax};
        }
        .menu :global(.x-icon) {
          cursor: pointer;
          position: absolute;
          right: 8px;
          top: 8px;
          width: 32px;
          height: 32px;
          fill: #fff;
        }
        .menu ul {
          user-select: none;
          display: flex;
          flex-direction: column;
          justify-content: center;
          list-style: none;
        }
        .menu .link {
          font-size: 36px;
          font-weight: bold;
          color: #fff;
          display: flex;
          align-items: center;
          margin-top: 36px;
        }
        .menu .link-image {
          display: block;
          width: 48px;
          height: 48px;
        }
        .menu .link-text {
          margin-left: 16px;
        }
        /*
        .navbar-ad-container {
          display: none;
        }
        @media screen and (min-width: ${theme.containerMin}) {
          .navbar {
            padding: 0 ${displayAd ? "48px" : 0};
          }
          .navbar-ad-container {
            display: block;
          }
        }
        */
      `}</style>
      <div className="navbar-container">
        <div className="navbar">
          <Logo size={3} />
          {displayAd && (
            <div className="navbar-ad-container">
              <AdUnit
                desktop="awardjoy_navbar_desk"
                noLabel
                marginBottom={0}
                marginTop={0}
                desktopHeight={90}
                desktopWidth={728}
              />
              {/* <GoogleAdUnit mobile={false} tablet={false} slot={NAVBAR_SLOT} label={false} /> */}
            </div>
          )}
          <HamburgerIcon onClick={handleClickMenu} />
        </div>
      </div>
      {showMenu && (
        <div className="menu-background">
          <div className="menu">
            <XIcon onClick={() => setShowMenu(false)} />
            <ul>
              {categories.map((category) => (
                <a href={getCategoryUrl(category)} key={category.slug}>
                  <li className="link">
                    <img className="link-image" src={cloudflareImage(category.icon)} />
                    <span className="link-text">{category.name}</span>
                  </li>
                </a>
              ))}
            </ul>
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;
