import { useState } from "react";

export function localStorageAvailable() {
  let test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

class Storage {
  static get(key) {
    if (typeof localStorage === "undefined") {
      return null;
    }
    return localStorage.getItem(key);
  }
  static set(key, value) {
    if (typeof localStorage === "undefined") {
      return;
    }
    localStorage.setItem(key, value);
  }
}

export function useLocalState(storageKey, initialValue) {
  const [localValue, setLocalValue] = useState(Storage.get(storageKey) || initialValue);
  return [
    localValue,
    (value) => {
      Storage.set(storageKey, value);
      setLocalValue(value);
    },
  ];
}
