import { getURL } from "next/dist/next-server/lib/utils";
import { useRouter } from "next/router";

export function getCategoryUrl(category) {
  return `/quizzes/${category.slug}`;
}

export function getUrl(object, extra = null) {
  let url;
  if (object.type === "scored") {
    url = `/quiz/s/${object.slug}`;
  } else if (object.type === "bucketed") {
    url = `/quiz/b/${object.slug}`;
  } else if (object.type === "article") {
    url = `/a/${object.slug}`;
  } else if (object.type === "listicle") {
    url = `/l/${object.slug}`;
  } else {
    throw new Error(`Unknown object of type ${object.type}`);
  }
  if (extra !== null) {
    url += `/${extra}`;
  }
  return url;
}

export function getAbsoluteUrl(path) {
  return "https://www.awardjoy.com" + path;
}

export function getCanonicalUrl() {
  const router = useRouter();
  const path = router.asPath.split("?")[0];
  return "https://www.awardjoy.com" + path;
}
