import { useEffect, useState } from "react";
import {
  BareFacebookIcon,
  BareInstagramIcon,
  BarePinterestIcon,
  BareTwitterIcon,
} from "components/Icons";
import Logo from "components/Logo";
import { apiPost } from "lib/api";
import { localStorageAvailable } from "lib/localstorage";
import theme from "theme";

const STORAGE_KEY = "awardjoy-newsletter";

function Footer() {
  const [email, setEmail] = useState("");
  const [emailSubmitted, setEmailSubmitted] = useState();

  useEffect(() => {
    if (localStorageAvailable()) {
      if (localStorage.getItem(STORAGE_KEY)) {
        //setEmailSubmitted(true);
      }
    }
  }, []);

  function handleSignup() {
    localStorage.setItem(STORAGE_KEY, true);
    apiPost("/newsletter/signup/", { email });
    setEmailSubmitted(true);
  }

  return (
    <footer>
      <style jsx>{`
        .inner {
          max-width: ${theme.maxWidth};
          margin: 0 auto;
        }
        .lower-block {
          background: ${theme.hsl(theme.darken(theme.blue))};
          color: #eee;
          padding: 32px;
        }

        .upper-block {
          background: ${theme.hsl(theme.darkBackground)};
          color: #eee;
          padding: 32px;
        }

        .logo-container {
          display: flex;
          width: 100%;
          justify-content: center;
        }

        .social-container {
          margin-top: 36px;
          display: flex;
          width: 100%;
          justify-content: center;
          flex-wrap: wrap;
        }
        .social-container :global(.icon) {
          margin-left: 12px;
          margin-top: 12px;
          fill: #fff;
          background: ${theme.hsl(theme.darkBackground)};
          border-radius: 16px;
          width: 48px;
          padding: 8px;
          height: 48px;
        }
        .blurb-container {
          margin-top: 36px;
        }
        .blurb-container p {
          text-align: center;
          width: 100%;
          max-width: 400px;
          margin: 0 auto;
          font-size: 18px;
          line-height: 200%;
          color: #fff;
        }
        .newsletter-container {
          margin-top: 36px;
          display: flex;
          align-items: center;
          flex-direction: column;
        }
        .newsletter-container p {
          padding: 24px;
        }
        .newsletter-container h2 {
          font-size: 30px;
        }
        .newsletter-container .form {
          margin-top: 36px;
          display: flex;
          justify-content: center;
          width: 100%;
        }
        .newsletter-container input {
          padding: 8px;
          font-size: 18px;
          line-height: 18px;
          width: 100%;
          max-width: 400px;
          border: none;
          outline: none;
          border-radius: 8px;
        }
        .newsletter-container button {
          margin-left: 12px;
          font-size: 24px;
          padding: 12px 36px;
        }
        .links-container {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .links-container a {
          font-size: 24px;
          padding: 24px;
        }
        .credit-container {
          margin-top: 64px;
          color: #ddd;
          text-align: center;
        }
        .copyright {
          margin-top: 32px;
          margin-bottom: 128px;
          text-align: center;
          color: #ddd;
        }

        h2 {
          text-align: center;
        }

        .button {
          background: ${theme.hsl(theme.primaryButtonBackground)};
          color: #fff;
        }
        .button:hover {
          background: ${theme.hsl(theme.primaryButtonBackgroundHover)};
        }
      `}</style>
      <div className="upper-block">
        <div className="inner">
          <div className="logo-container">
            <Logo darkBackground size={4} />
          </div>
          <div className="social-container">
            <a target="_blank" href="https://www.facebook.com/awardjoy">
              <BareFacebookIcon />
            </a>
            <a target="_blank" href="https://twitter.com/AwardJoy">
              <BareTwitterIcon />
            </a>
            <a target="_blank" href="https://pinterest.ca/awardjoy">
              <BarePinterestIcon />
            </a>
            <a target="_blank" href="https://www.instagram.com/award_joy/">
              <BareInstagramIcon />
            </a>
          </div>
          <div className="blurb-container">
            <p>
              Awardjoy has been making edutainment products since 2020. So, not very long. But hey,
              we're pretty good - see for yourself!
            </p>
          </div>
          <div className="newsletter-container">
            {emailSubmitted ? (
              <>
                <h2>Thank you!</h2>
                <p>We will send you a confirmation email shortly.</p>
              </>
            ) : (
              <>
                <h2>Get quizzes daily and win prizes!</h2>
                <div className="form">
                  <input
                    value={email}
                    placeholder="Email address"
                    type="email"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <button className="button" onClick={handleSignup}>
                    Submit
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="lower-block">
        <div className="inner">
          <div className="links-container">
            <a href="/contact">Contact Us</a>
            <a href="/terms">Our Terms</a>
            <a href="/privacy">Your Privacy</a>
            <a href="/dmca">DMCA</a>
            <a href="/about">About</a>
          </div>
          <div className="credit-container">
            Icons made by{" "}
            <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
              Freepik
            </a>
          </div>
          <div className="copyright">© 2020 Awardjoy. All rights reserved.</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
